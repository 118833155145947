.resultsBlock {
  background-image: url("../../../public/assets/backGames.png");
  background-size: cover; /* Изображение будет масштабироваться, чтобы покрыть весь элемент */
  background-position: center; /* Центрирование изображения */
  background-repeat: no-repeat; /* Избегаем повторения изображения */
  width: 100%;
  height: 100vh;
}

.resultsBlock {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding-top: 10%;
}

.gameBlock {
  height: 65%;
  padding-top: 7%;
}

.game {
  position: relative;
}

.imgFrameForGamesStyle {
  width: 95vw;
  height: auto;
}

.imgButtonStyle {
  width: 90vw;
  height: auto;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.imgButtonStyle:active {
  transform: scale(0.98);
}

.imgLightStyle {
  width: 85vw;
  height: auto;
}

.lightBlock {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.resultBlock {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textOneStyle {
  font-size: 10vw;
  color: white;
  font-family: "Chango", sans-serif;
  font-weight: 400;
  -webkit-text-stroke: 2px #000;
}

.textTwoStyle {
  font-size: 10vw;
  color: #ffcc00;
  font-family: "Chango", sans-serif;
  font-weight: 400;
  -webkit-text-stroke: 2px #000;
}
