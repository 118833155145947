.intro {
  background-image: url("../../../public/assets/backIntro.png");
  background-size: cover; /* Изображение будет масштабироваться, чтобы покрыть весь элемент */
  background-position: center; /* Центрирование изображения */
  background-repeat: no-repeat; /* Избегаем повторения изображения */
  width: 100%; /* Ширина элемента */
  height: 100vh; /* Высота элемента */
}

.introBlock {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
}

.imgButtonStyle {
  width: 90vw;
  height: auto;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.imgButtonStyle:active {
  transform: scale(0.98);
}
