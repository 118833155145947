.game-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.grid {
  display: grid;
  grid-template-columns: repeat(8, 38px);
  transform: rotate(90deg);
}

.block {
  width: 9vw;
  height: auto;
  border-radius: 5px;
  -webkit-transition: transform 0.5s ease-in;
  transition: transform 0.5s ease-in;
  -webkit-transform: translateY(0);
  transform: translateY(0);
}

.block.falling {
  -webkit-transform: translateY(100px);
  transform: translateY(100px);
}

.fade-out {
  animation: fadeOut 0.5s forwards;
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.falling {
  transition: transform 0.5s;
}

.score-board {
  margin-bottom: 20px;
}

.controls {
  margin-top: 20px;
}

button {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 16px;
}

button:hover {
  background-color: #45a049;
}
