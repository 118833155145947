.navBlock {
  width: 90vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 6%;
  z-index: 0;
}

.imgNavStyle {
  width: 15vw;
  height: auto;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.imgNavStyle:active {
  transform: scale(0.98);
}
