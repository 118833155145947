.gameTreeBlock {
  background-image: url("../../../public/assets/backGames.png");
  background-size: cover; /* Изображение будет масштабироваться, чтобы покрыть весь элемент */
  background-position: center; /* Центрирование изображения */
  background-repeat: no-repeat; /* Избегаем повторения изображения */
  width: 100%;
  height: 100vh;
}

.gameTreeBlock {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.navBlock {
  width: 100vw;
  display: flex;
  justify-content: center;
}

.gameBlock {
  height: 65%;
  padding-top: 7%;
}

.game {
  position: relative;
}

.weelBlock {
  position: absolute;
  top: 7%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.imgArrowWeelStyle {
  width: 155vw;
  height: auto;
  z-index: 20;
}

.imgRimStyle {
  width: 140vw;
  height: auto;
  z-index: 10;
}

.imgWeelStyle {
  width: 120vw;
  height: auto;
  z-index: 10;
}

.navigateComponent {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  background: linear-gradient(
    to bottom,
    rgba(10, 83, 96, 0.1) 0%,
    rgba(10, 83, 96, 0.5) 50%,
    rgba(7, 78, 92, 1) 100%
  );
  z-index: 50;
}

.navigateBlock {
  height: 30vh;
  width: 90vw;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
}

.countBlock {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.countFrame {
  position: relative;
}

.countDataBlock {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.textStyle {
  font-size: 2vw;
  color: white;
  font-family: "Chango", sans-serif;
  font-weight: 400;
}

.textStyleTwo {
  font-size: 3vw;
  color: white;
  font-family: "Chango", sans-serif;
  font-weight: 400;
  padding-left: 5px;
}

.textStyle {
  font-size: 2vw;
  color: white;
  font-family: "Chango", sans-serif;
  font-weight: 400;
}

.textStyleTree {
  font-size: 5vw;
  color: white;
  font-family: "Chango", sans-serif;
  font-weight: 400;
  padding-left: 5px;
}

.imgFrameForCountStyle {
  width: 30vw;
  height: auto;
}

.spinBlock {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgButtonSpinStyle {
  width: 20vw;
  height: auto;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.imgButtonSpinStyle:active {
  transform: scale(0.98);
}

.betBlock {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navBet {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.betFrame {
  position: relative;
}

.textBetBlock {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textStyleFour {
  font-size: 4vw;
  color: white;
  font-family: "Chango", sans-serif;
  font-weight: 400;
  padding-left: 5px;
}

.imgButtonNavStyle {
  width: 10vw;
  height: auto;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.imgButtonNavStyle:active {
  transform: scale(0.98);
}

.weelArrowBlock {
  position: relative;
}

.arrowBlock {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.imgArrowStyle {
  height: 10vh;
  width: auto;
  z-index: 30;
}
