.menuBlock {
  background-image: url("../../../public/assets/backGames.png");
  background-size: cover; /* Изображение будет масштабироваться, чтобы покрыть весь элемент */
  background-position: center; /* Центрирование изображения */
  background-repeat: no-repeat; /* Избегаем повторения изображения */
  width: 100%;
  height: 100vh;
}

.menuBlock {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.navBlock {
  width: 90vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 6%;
}

.imgNavStyle {
  width: 15vw;
  height: auto;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.imgNavStyle:active {
  transform: scale(0.98);
}

.imgRoadStyle {
  width: 90vw;
  height: 40vh;
}

.gamesBlock {
  position: relative;
  height: 60%;
  width: 90vw;
}

.roadBlock {
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.gameFourBlock {
  position: absolute;
  top: 0;
  left: 0;
}

.imgGameFourStyle {
  width: auto;
  height: 25vh;
  transition: transform 0.3s ease;
}

.imgGameFourStyle:active {
  transform: scale(1.2);
}

.gameTreeBlock {
  position: absolute;
  top: 30%;
  right: 10%;
}

.imgGameTreeStyle {
  width: auto;
  height: 12vh;
  transition: transform 0.3s ease;
}

.imgGameTreeStyle:active {
  transform: scale(1.2);
}

.gameTwoBlock {
  position: absolute;
  top: 58%;
  left: 0;
}

.imgGameTwoStyle {
  width: auto;
  height: 10vh;
  transition: transform 0.3s ease;
}

.imgGameTwoStyle:active {
  transform: scale(1.2);
}

.gameOneBlock {
  position: absolute;
  bottom: 0;
  right: 5%;
}

.imgGameOneStyle {
  width: auto;
  height: 19vh;
  transition: transform 0.3s ease;
}

.imgGameOneStyle:active {
  transform: scale(1.2);
}

.buttonBlock {
  padding-top: 10%;
}

.imgButtonStyle {
  width: 90vw;
  height: auto;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.imgButtonStyle:active {
  transform: scale(0.98);
}
