.slotsMachine {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.slotBlock {
  width: 30vw;
  height: 37vh;
  overflow: hidden;
  margin: 0 -10px;
}

.slotInner {
  display: flex;
  flex-direction: column;
}

.slotsItem {
  position: relative;
  width: 100%;
  height: 12.5vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.slotsItemImage {
  width: 20vw;
  height: auto;
  z-index: 10;
}

.lightSlotsImage {
  height: 50%;
  width: 50%;
}
