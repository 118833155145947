.settingsBlock {
  background-image: url("../../../public/assets/backGames.png");
  background-size: cover; /* Изображение будет масштабироваться, чтобы покрыть весь элемент */
  background-position: center; /* Центрирование изображения */
  background-repeat: no-repeat; /* Избегаем повторения изображения */
  width: 100%;
  height: 100vh;
}

.settingsBlock {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.navBlock {
  width: 90vw;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 6%;
}

.imgNavStyle {
  width: 15vw;
  height: auto;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.imgNavStyle:active {
  transform: scale(0.98);
}

.gameBlock {
  height: 65%;
  padding-top: 7%;
}

.game {
  position: relative;
}

.imgFrameForGamesStyle {
  width: 95vw;
  height: auto;
}

.imgButtonStyle {
  width: 90vw;
  height: auto;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.imgButtonStyle:active {
  transform: scale(0.98);
}

.imgLightStyle {
  width: 85vw;
  height: auto;
}

.textOneStyle {
  font-size: 10vw;
  color: white;
  font-family: "Chango", sans-serif;
  font-weight: 400;
  -webkit-text-stroke: 2px #000;
}

.textTwoStyle {
  font-size: 5vw;
  color: white;
  font-family: "Chango", sans-serif;
  font-weight: 400;
}

.textPopup {
  font-size: 4vw;
  color: white;
  font-family: "Chango", sans-serif;
  font-weight: 400;
}

.switchBlock {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.settingsText {
  padding-bottom: 5%;
}

.switchSoundBlock {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.switchComponent {
  height: 7vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.switchSoundBack {
  position: relative;
  width: 60vw;
  height: 3.5vh;
  border: 3px solid #ffb775;
  background-color: white;
  border-radius: 3.5vw;
}

.switchMusicBack {
  position: relative;
  width: 60vw;
  height: 3.5vh;
  border: 3px solid #52a9ac;
  background-color: white;
  border-radius: 3.5vw;
}

.imageScroll {
  width: auto;
  height: 3.5vh;
}

.popupResScore {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  transition: top 0.5s ease-out;
}

.popupResScoreBlock {
  width: 60vw;
  height: 6vh;
  border-radius: 5vw;
  background-color: rgba(255, 225, 225, 0.3);
  display: flex;
  align-items: center;
  justify-content: center;
}

.popupResScore.active {
  top: 3%;
}
