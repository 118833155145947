.gameTwoBlock {
  background-image: url("../../../public/assets/backGames.png");
  background-size: cover; /* Изображение будет масштабироваться, чтобы покрыть весь элемент */
  background-position: center; /* Центрирование изображения */
  background-repeat: no-repeat; /* Избегаем повторения изображения */
  width: 100%;
  height: 100vh;
  position: relative;
}

.timerBlock {
  position: absolute;
  top: 5%;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 50;
}

.timerTextBlock {
  width: 35vw;
  display: flex;
  justify-content: center;
}

.timerImg {
  width: 10vw;
  height: auto;
}

.textTimer {
  color: white;
  font-size: 8vw;
  font-family: "Chango", sans-serif;
  font-weight: 500;
}

.gameTwoBlock {
  height: 100vh;
  display: flex;
  align-items: center;
  flex-direction: column;
}

.navBlock {
  width: 100vw;
  display: flex;
  justify-content: center;
  z-index: 60;
}

.gameBlock {
  height: 65%;
  padding-top: 7%;
}

.game {
  position: relative;
}

.gameMatchBlock {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgFrameForGamesStyle {
  width: 95vw;
  height: auto;
}

.navigateBlock {
  display: flex;
  flex-direction: row;
  width: 95vw;
  justify-content: space-between;
}

.countBlock {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.countFrame {
  position: relative;
}

.countDataBlock {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.textStyle {
  font-size: 2vw;
  color: white;
  font-family: "Chango", sans-serif;
  font-weight: 400;
}

.textStyleTwo {
  font-size: 3vw;
  color: white;
  font-family: "Chango", sans-serif;
  font-weight: 400;
  padding-left: 5px;
}

.textStyle {
  font-size: 2vw;
  color: white;
  font-family: "Chango", sans-serif;
  font-weight: 400;
}

.textStyleTree {
  font-size: 5vw;
  color: white;
  font-family: "Chango", sans-serif;
  font-weight: 400;
  padding-left: 5px;
}

.imgFrameForCountStyle {
  width: 30vw;
  height: auto;
}

.spinBlock {
  width: 30%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.imgButtonSpinStyle {
  width: 20vw;
  height: auto;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.imgButtonSpinStyle:active {
  transform: scale(0.98);
}

.betBlock {
  width: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.navBet {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.betFrame {
  position: relative;
}

.textBetBlock {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textStyleFour {
  font-size: 4vw;
  color: white;
  font-family: "Chango", sans-serif;
  font-weight: 400;
  padding-left: 5px;
}

.imgButtonNavStyle {
  width: 10vw;
  height: auto;
  transition: background-color 0.3s ease, transform 0.1s ease;
}

.imgButtonNavStyle:active {
  transform: scale(0.98);
}
